import Service from "@/helpers/service";

export default {
  get() {
    return Service().get("ABC/GoodIssued");
  },
  getPivot() {
    return Service().get("ABC/GoodIssuedPivot");
  },
  getPivotYear(year) {
    return Service().get("ABC/GoodIssuedPivot/by-year/" + year);
  },
  getPivotMonth(params) {
    return Service().get("ABC/GoodIssuedPivot/by-month", {params});
  },
  generate() {
    return Service().get("ABC/GoodIssuedPivot/generate");
  },
};
